<template>
  <div>
    <Toast position="top-right" />
    <div class="card card-custom gutter-b">
      <div class="card-header" v-b-toggle.collapse-filtros>
        <div class="card-title mb-0">
          <h5 class="card-label mb-0">Filtros</h5>
        </div>
        <div class="card-toolbar">
          <a class="btn btn-icon btn-sm btn-light-primary mr-1">
            <i
              class="ki icon-nm"
              :class="{
                'ki-arrow-up': cardFiltros,
                'ki-arrow-down': !cardFiltros
              }"
            ></i>
          </a>

          <a
            class="btn btn-sm btn-light-warning mr-1 font-weight-bold"
            @click.prevent="filtrarRelatorio"
          >
            Filtrar
          </a>
        </div>
      </div>

      <b-collapse id="collapse-filtros" v-model="cardFiltros">
        <div class="card-body">
          <div class="row">
            <div class="col-md-3">
              <div class="form-group">
                <label>Conta</label>
                <pv-multiselect
                  class="form-control"
                  optionLabel="text"
                  emptyFilterMessage="Nenhum resultado"
                  :filter="true"
                  :options="sltContas"
                  placeholder="Todos"
                  v-model="defaultsSelect.conta"
                  @input="obterContas"
                >
                </pv-multiselect>
              </div>
            </div>

            <div class="col-md-3">
              <div class="form-group">
                <label>Período</label>
                <pv-dropdown
                  class="form-control"
                  optionLabel="text"
                  emptyFilterMessage="Nenhum resultado"
                  :filter="true"
                  :options="sltPeriodos"
                  v-model="defaultsSelect.periodo"
                  @input="obterPeriodo"
                ></pv-dropdown>
              </div>
            </div>

            <div class="col-md-3" v-show="exibirDatasPeriodo">
              <div class="form-group">
                <label>Data Inicial</label>
                <b-form-datepicker
                  v-model="frmFiltrarRelatorio.dataInicial"
                ></b-form-datepicker>
              </div>
            </div>

            <div class="col-md-3" v-show="exibirDatasPeriodo">
              <div class="form-group">
                <label>Data Final</label>
                <b-form-datepicker
                  v-model="frmFiltrarRelatorio.dataFinal"
                ></b-form-datepicker>
              </div>
            </div>

            <div class="col-md-3">
              <div class="form-group">
                <label>Tipo de Plano</label>
                <pv-dropdown
                  class="form-control"
                  optionLabel="text"
                  emptyFilterMessage="Nenhum resultado"
                  :filter="true"
                  :options="sltTipoPlano"
                  :showClear="true"
                  placeholder="Todos"
                  v-model="defaultsSelect.tipoPlano"
                  @input="obterTipoPlano"
                ></pv-dropdown>
              </div>
            </div>

            <div
              class="col-md-3"
              v-if="this.frmFiltrarRelatorio.periodo == 'MAN' && isAdmin"
            >
              <div class="form-group">
                <label>Lançamento</label>
                <pv-dropdown
                  class="form-control"
                  optionLabel="text"
                  emptyFilterMessage="Nenhum resultado"
                  :filter="true"
                  :options="sltFaturamento"
                  :showClear="true"
                  placeholder="Todos"
                  v-model="defaultsSelect.faturamento"
                  @input="obterFaturamento"
                ></pv-dropdown>
              </div>
            </div>

            <div
              class="col-md-3"
              v-if="this.frmFiltrarRelatorio.periodo == 'MAN' && isAdmin"
            >
              <div class="form-group">
                <label>Faturar</label>
                <pv-dropdown
                  class="form-control"
                  optionLabel="text"
                  emptyFilterMessage="Nenhum resultado"
                  :filter="true"
                  :options="sltFaturar"
                  :showClear="true"
                  placeholder="Todos"
                  v-model="defaultsSelect.faturar"
                  @input="obterFaturar"
                ></pv-dropdown>
              </div>
            </div>

            <div class="col-md-3" v-if="isAdmin">
              <b-form-group>
                <label>ID Integração:</label>
                <b-form-input
                  v-model="frmFiltrarRelatorio.idIntegracao"
                ></b-form-input>
              </b-form-group>
            </div>
          </div>

          <b-form-group v-if="isAdmin">
            <div class="checkbox-inline">
              <label class="checkbox font-weight-normal">
                <input
                  type="checkbox"
                  name="forcarExibicaoContasSubcontas"
                  v-model="frmFiltrarRelatorio.forcarExibicaoContasSubcontas"
                />
                <span></span>
                Forçar exibição de contas e subcontas
              </label>

              <label class="checkbox font-weight-normal">
                <input
                  type="checkbox"
                  name="ocultarContasSemProcessamento"
                  v-model="frmFiltrarRelatorio.ocultarContasSemProcessamento"
                />
                <span></span>
                Ocultar contas sem processamento
              </label>
            </div>
          </b-form-group>
        </div>
      </b-collapse>
    </div>

    <div class="card card-custom">
      <div class="card-header">
        <div class="card-title">
          <h5 class="card-label">Resultados</h5>
        </div>

        <div class="card-toolbar">
          <b-button
            variant="success"
            class="mr-2"
            @click.prevent="gerarCobrancaMultipla"
            v-if="
              contasSelecionadas.length > 0 &&
              ultimosFiltrosUtilizados.periodo == 'MAN'
            "
            >Gerar Cobrança</b-button
          >

          <b-form-radio-group
            :buttons="true"
            v-model="visaoRelatorio"
            :options="tiposVisaoRelatorio"
          ></b-form-radio-group>
        </div>
      </div>
      <div class="card-body">
        <b-table
          hover
          small
          :fields="cabecalho.cabecalho"
          :items="linhas"
          show-empty
        >
          <template #thead-top>
            <tr v-if="cabecalho.colspan > 0">
              <th></th>
              <th
                v-if="isAdmin && ultimosFiltrosUtilizados.periodo == 'MAN'"
              ></th>
              <th
                v-for="(topo, chave) in cabecalho.topo"
                class="text-center"
                :class="topo.classes"
                :colspan="cabecalho.colspan"
                :key="chave"
              >
                {{ topo.descricao }}
              </th>
              <th></th>
            </tr>
          </template>

          <template #empty>
            <p class="text-center mb-0">Nenhum registro encontrado.</p>
          </template>

          <template #head(selecionar)>
            <b-form-checkbox
              v-model="todasContasSelecionadas"
              size="lg"
              @change="alternarContasSelecionadas"
            >
            </b-form-checkbox>
          </template>

          <template #cell(selecionar)="row">
            <b-form-checkbox
              v-model="contasSelecionadas"
              :value="row.item"
              :key="row.index"
              size="lg"
              v-if="row.item.faturar == 'S'"
            >
            </b-form-checkbox>
          </template>

          <template #cell(acoes)="row">
            <b-button size="sm" @click="detalhes(row)" class="font-weight-bold">
              Detalhes
            </b-button>
            <b-button
              size="sm"
              v-if="isAdmin && row.item.whatsapp_oficial == 'S'"
              @click="mensagens(row)"
              class="ml-2 font-weight-bold"
            >
              Custos
            </b-button>
            <b-button
              size="sm"
              @click="gerarCobranca(row.item)"
              class="ml-2 font-weight-bolder"
              :variant="
                obterCorBtnFaturar(row.item.faturado, row.item.plano_faturado)
              "
              v-if="
                isAdmin &&
                row.item.existe_integracao == 'S' &&
                ultimosFiltrosUtilizados.periodo == 'MAN' &&
                row.item.faturar == 'S'
              "
            >
              R$
            </b-button>
          </template>

          <template #row-details="row">
            <div class="px-10">
              <b-table
                :fields="cabecalhoDetalhes"
                :items="detalhesContas[row.index]"
              >
                <template #cell(tipo)="item">
                  <span
                    class="label label-success label-pill label-inline"
                    v-if="item.value == 'Envio'"
                    >{{ item.value }}</span
                  >
                  <span
                    class="label label-primary label-pill label-inline"
                    v-else
                    >{{ item.value }}</span
                  >
                </template>
              </b-table>
            </div>
          </template>

          <template #custom-foot>
            <b-tr>
              <th
                v-if="isAdmin && ultimosFiltrosUtilizados.periodo == 'MAN'"
              ></th>
              <b-th :class="['align-middle']">Total</b-th>
              <b-th
                :class="['align-middle', 'text-center']"
                v-for="(valor, chave) in total"
                v-bind:key="chave"
                >{{ Number.isInteger(valor) ? formatarInteiro(valor) : valor }}
              </b-th>
            </b-tr>
          </template>
        </b-table>
      </div>
    </div>

    <modal-gerar-cobranca
      ref="mdlGerarCobranca"
      :conta="contaSelecionada"
      :planos="sltPlanos"
      @fechar:cobranca="limparSelecaoConta"
    ></modal-gerar-cobranca>

    <modal-custo-mensagens
      :custos="custoMensagemsSelecionada"
      ref="mdlCustoMensagens"
      :textoPeriodo="textoPeriodo"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import ModalGerarCobranca from "./ModalGerarCobranca";
import ModalCustoMensagens from "./ModalCustoMensagens";
import Toast from "primevue/toast";

import Swal from "sweetalert2";
import moment from "moment";

export default {
  components: { ModalGerarCobranca, ModalCustoMensagens, Toast },
  name: "relatorio-consolidado",
  data() {
    return {
      defaultsSelect: {
        periodo: {
          value: "MA",
          text: "Mês atual"
        },
        conta: [],
        tipoPlano: null,
        faturamento: null,
        faturar: null
      },
      frmFiltrarRelatorio: {
        conta: null,
        periodo: "MA",
        dataInicial: "",
        dataFinal: "",
        tipoPlano: null,
        faturamento: null,
        faturar: null,
        forcarExibicaoContasSubcontas: false,
        idIntegracao: null,
        ocultarContasSemProcessamento: false
      },
      ultimosFiltrosUtilizados: {
        conta: null,
        periodo: null,
        dataInicial: "",
        dataFinal: "MA",
        tipoPlano: null,
        faturamento: null,
        faturar: null,
        forcarExibicaoContasSubcontas: false,
        idIntegracao: null,
        ocultarContasSemProcessamento: false
      },
      cabecalhos: {
        tipo: {
          cabecalho: [
            {
              key: "conta",
              label: "Conta",
              sortable: false,
              tdClass: "align-middle"
            }
          ],
          colspan: 0
        }
      },
      resultado: [],
      totais: {
        tipo: []
      },
      sltContas: [],
      sltPeriodos: [],
      sltTipoPlano: [
        {
          value: "PRE",
          text: "Pré-pago"
        },
        {
          value: "POS",
          text: "Pós-pago"
        }
      ],
      sltFaturamento: [
        {
          value: "N",
          text: "Não lançado"
        },
        {
          value: "S",
          text: "Lançado"
        }
      ],
      sltFaturar: [
        {
          value: "N",
          text: "Não"
        },
        {
          value: "S",
          text: "Sim"
        }
      ],
      cardFiltros: false,
      visaoRelatorio: "tipo",
      tiposVisaoRelatorio: [
        {
          text: "Por Tipo",
          value: "tipo"
        },
        {
          text: "Por Canal",
          value: "canal"
        }
      ],
      detalhesContas: [],
      cabecalhoDetalhes: [
        {
          key: "campanha",
          label: "Campanha"
        },
        {
          key: "quantidade",
          label: "Quantidade",
          class: "text-center"
        },
        {
          key: "tipo",
          label: "Tipo",
          class: "text-center"
        }
      ],
      contaSelecionada: {},
      custoMensagemsSelecionada: {},
      sltPlanos: [],
      todasContasSelecionadas: false,
      contasSelecionadas: []
    };
  },
  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Relatório Resumido" }]);
    this.inicializar();
  },
  methods: {
    inicializar() {
      ApiService.get("relatorio/consolidado").then((response) => {
        this.cabecalhos = response.data.data.cabecalhos;
        this.resultado = response.data.data.resultado;
        this.sltContas = response.data.data.sltContas;
        this.sltPeriodos = response.data.data.sltPeriodos;
        this.totais = response.data.data.totais;
        this.sltPlanos = response.data.data.sltPlanos;
      });
    },

    filtrarRelatorio(e) {
      e.stopPropagation();

      this.contasSelecionadas = [];

      ApiService.post(
        "relatorio/consolidado/filtrar",
        this.frmFiltrarRelatorio
      ).then((response) => {
        this.cabecalhos = response.data.data.cabecalhos;
        this.resultado = response.data.data.resultado;
        this.totais = response.data.data.totais;

        this.ultimosFiltrosUtilizados = { ...this.frmFiltrarRelatorio };

        this.cardFiltros = false;
      });
    },

    obterContas(arr) {
      this.frmFiltrarRelatorio.conta = arr.map((o) => o.value);
    },

    obterPeriodo(obj) {
      this.frmFiltrarRelatorio.periodo = obj ? obj.value : null;
    },

    obterTipoPlano(obj) {
      this.frmFiltrarRelatorio.tipoPlano = obj ? obj.value : null;
    },

    obterFaturamento(obj) {
      this.frmFiltrarRelatorio.faturamento = obj ? obj.value : null;
    },

    obterFaturar(obj) {
      this.frmFiltrarRelatorio.faturar = obj ? obj.value : null;
    },

    async detalhes(linha) {
      if (!linha.detailsShowing) {
        const filtros = { ...this.ultimosFiltrosUtilizados };
        filtros.conta = [linha.item.conta];

        await ApiService.post(
          "relatorio/consolidado/obter-detalhes-consolidado",
          filtros
        ).then((res) => {
          this.detalhesContas[linha.index] = res.data.data;
        });
      }

      linha.toggleDetails();
    },

    async mensagens(linha) {
      const filtros = { ...this.ultimosFiltrosUtilizados };
      filtros.conta = [linha.item.conta];

      await ApiService.post(
        "relatorio/consolidado/obter-custos-mensagens-consolidado",
        filtros
      ).then((res) => {
        this.custoMensagemsSelecionada = res.data;
        if (!this.custoMensagemsSelecionada.length) {
          this.$toast.add({
            severity: "info",
            summary: "Sem informações de custos",
            detail:
              "O sistema não retornou nenhum custo para " +
              linha.item.nome_conta,
            life: 7000
          });
          return;
        }
        this.$refs.mdlCustoMensagens.abrir();
      });
    },

    gerarCobranca(conta) {
      this.contaSelecionada = conta;
      this.$refs.mdlGerarCobranca.abrir();
    },

    limparSelecaoConta() {
      this.contaSelecionada = {};
    },

    obterCorBtnFaturar(faturado, plano_faturado) {
      return faturado == "S"
        ? plano_faturado == 0
          ? "warning"
          : "primary"
        : "success";
    },

    alternarContasSelecionadas() {
      this.contasSelecionadas = [];
      if (this.todasContasSelecionadas) {
        this.linhas.forEach((conta) => {
          if (conta.faturar == "S") {
            this.contasSelecionadas.push(conta);
          }
        });
      }
    },

    gerarCobrancaMultipla() {
      let countMessage = "";
      if (this.qtdContasSelecionadas === 0) {
        countMessage = "Nenhuma conta selecionada";
      } else if (this.qtdContasSelecionadas === 1) {
        countMessage = "1 conta selecionada";
      } else {
        countMessage = `${this.qtdContasSelecionadas} contas selecionadas`;
      }
      Swal.fire({
        title: `Informe a data da venda e confirme para gerar as cobranças das contas selecionadas:`,
        showCancelButton: true,
        confirmButtonText: "Gerar Cobrança",
        cancelButtonText: "Cancelar",
        customClass: {
          cancelButton: "btn btn-secondary",
          confirmButton: "btn btn-success"
        },
        buttonsStyling: false,
        html: `<p>${countMessage}</p>
         <input type="date" class="form-control" id="iptDataVenda">`
      }).then((result) => {
        if (result.value) {
          const dataVenda = document.getElementById("iptDataVenda").value;
          const dataValida = moment(dataVenda, "YYYY-MM-DD", true).isValid();

          if (dataValida) {
            const dataVendaIso = moment(dataVenda, "YYYY-MM-DD").toISOString(
              true
            );

            ApiService.post(`conta/integracao/gerar-cobranca-multipla`, {
              contas: this.contasSelecionadas,
              dataVenda: dataVendaIso
            }).then((res) => {
              const contasNaoFaturadas = res.data.data;

              this.contasSelecionadas.forEach((conta) => {
                const index = this.linhas.findIndex(
                  (linha) => linha.conta === conta.conta
                );

                this.linhas[index].faturado = "S";
              });

              if (contasNaoFaturadas.length == 0) {
                Swal.fire({
                  title: "MENSAGEM",
                  text: "A cobrança das contas selecionadas foi gerada com sucesso!",
                  icon: "success",
                  heightAuto: false
                });
              } else if (
                contasNaoFaturadas.length > 0 &&
                contasNaoFaturadas.length < this.contasSelecionadas.length
              ) {
                let stringContasNaoFaturadas =
                  "Algumas contas não puderam ser faturadas: <br>";

                contasNaoFaturadas.forEach((conta) => {
                  stringContasNaoFaturadas += `${conta.nome_conta}, `;
                });

                Swal.fire({
                  title: "MENSAGEM",
                  html: stringContasNaoFaturadas.slice(0, -2),
                  icon: "warning",
                  heightAuto: false
                });
              } else {
                Swal.fire({
                  title: "MENSAGEM",
                  html: "Nenhuma conta foi faturada! Verifique a contas selecionadas e tente novamente!",
                  icon: "error",
                  heightAuto: false
                });
              }
            });
          } else {
            this.gerarCobrancaMultipla();
          }
        }
      });
    }
  },
  computed: {
    ...mapGetters(["isAdmin"]),
    qtdContasSelecionadas() {
      return this.contasSelecionadas.length || 0;
    },

    exibirDatasPeriodo() {
      return this.frmFiltrarRelatorio.periodo == "personalizado";
    },

    cabecalho() {
      const obj = JSON.parse(
        JSON.stringify(this.cabecalhos[this.visaoRelatorio])
      );

      if (this.isAdmin && this.ultimosFiltrosUtilizados.periodo == "MAN") {
        obj.cabecalho.unshift({
          key: "selecionar",
          label: "Selecionar",
          sortable: false,
          class: "text-center align-middle"
        });
      }

      obj.cabecalho.push({
        key: "acoes",
        label: "Ações",
        sortable: false,
        class: "text-center"
      });

      return obj;
    },

    linhas() {
      return this.resultado;
    },

    total() {
      const obj = JSON.parse(JSON.stringify(this.totais[this.visaoRelatorio]));
      obj.acoes = "";
      return obj;
    },

    textoPeriodo() {
      return this.sltPeriodos.find(
        (i) => i.value == this.ultimosFiltrosUtilizados.periodo
      )?.text;
    }
  },
  watch: {
    contasSelecionadas(contasSelecionadas) {
      let contasFaturaveis = [];
      this.linhas.forEach((conta) => {
        if (conta.faturar == "S") {
          contasFaturaveis.push(conta);
        }
      });

      this.todasContasSelecionadas =
        contasSelecionadas.length > 0 &&
        contasFaturaveis.length === contasSelecionadas.length
          ? true
          : false;
    }
  }
};
</script>
