<template>
  <div>
    <b-modal
      ref="mdlGerarCobranca"
      id="mdlGerarCobrancaId"
      title="Gerar Cobrança"
      ok-title="Gerar"
      ok-variant="success"
      @ok="gerarCobranca"
      cancel-title="Fechar"
      size="lg"
      ignore-enforce-focus-selector=".swal2-container, .p-dropdown-panel"
      @shown="validacao"
      @hidden="limpar"
      no-close-on-esc
      no-close-on-backdrop
    >
      <form ref="frmGerarCobranca">
        <div class="alert alert-warning" role="alert" v-if="faturado">
          <p>
            Esta conta já foi faturada no mês atual, ao continuar, o faturamento
            anterior será excluído e um novo faturamento será gerado.
          </p>
          <div class="border-bottom border-white opacity-20 mb-5"></div>
          <p class="mb-0">
            <span class="font-weight-bolder">IMPORTANTE:</span> a exclusão será
            feita apenas no COMUNICANTE, o sistema integrado não será afetado.
          </p>
        </div>

        <div class="row">
          <div class="col-6">
            <p>
              <b>Cliente:</b> ({{ conta.codigo_integracao }})
              {{ conta.cliente }}
            </p>
          </div>

          <div class="col-6">
            <p><b>Conta:</b> ({{ conta.conta }}) {{ conta.nome_conta }}</p>
          </div>
        </div>

        <div class="row">
          <div class="col-3">
            <p><b>Enviado:</b> {{ enviado }}</p>
          </div>

          <div class="col-3">
            <p><b>Recebido:</b> {{ recebido }}</p>
          </div>

          <div class="col-3">
            <p>
              <b>Total:</b>
              {{ total }}
            </p>
          </div>

          <div class="col-3">
            <p>
              <b>Total (desconto):</b>
              {{ quantidadeDesconto }}
            </p>
          </div>
        </div>

        <div class="row">
          <div class="col-3">
            <p>
              <b>Franquia Mínima:</b>
              {{ conta.franquia_minima }}
            </p>
          </div>

          <div class="col-3">
            <p>
              <b>Qtd. Cobrança:</b>
              {{ quantidade }}
            </p>
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <b-form-group>
              <label>Plano:</label>
              <pv-dropdown
                class="form-control"
                optionLabel="text"
                emptyFilterMessage="Nenhum resultado"
                :filter="true"
                :options="planos"
                v-model="defaultPlano"
                @input="obterPlano"
                appendTo="mdlGerarCobrancaId"
                name="plano"
              ></pv-dropdown>
            </b-form-group>
          </div>

          <div class="col-3">
            <b-form-group>
              <label>Valor com desconto:</label>
              <b-form-input
                v-model="desconto"
                data-inputmask="'alias': 'currency', 'allowMinus': false, 'radixPoint': ','"
                name="desconto"
              ></b-form-input>
            </b-form-group>
          </div>

          <div class="col-3">
            <b-form-group>
              <label>Data da venda:</label>
              <b-form-input
                v-model="dataVenda"
                type="date"
                name="dataVenda"
              ></b-form-input>
            </b-form-group>
          </div>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import ApiService from "@/core/services/api.service";
import Inputmask from "inputmask";

import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";

export default {
  props: ["conta", "planos"],
  data() {
    return {
      desconto: 0,
      plano: null,
      quantidade: 0,
      quantidadeDesconto: 0,
      defaultPlano: {
        text: "Selecione",
        value: null
      },
      faturado: false,
      enviado: 0,
      recebido: 0,
      total: 0,
      dataVenda: null
    };
  },

  watch: {
    conta(conta) {
      this.desconto = 0;
      this.quantidade = 0;
      this.quantidadeDesconto = 0;
      this.faturado = false;
      this.enviado = conta.total_envios;
      this.recebido = conta.total_respostas;
      this.total = conta.total_geral;

      this.plano = conta.plano;
      this.defaultPlano = {
        text: conta.nome_plano,
        value: conta.plano
      };

      if (Object.keys(conta).length > 0) {
        ApiService.post(`conta/integracao/obter-dados-cobranca`, {
          idConta: conta.conta,
          idPlano: conta.plano,
          totalEnvios: conta.total_envios,
          totalRespostas: conta.total_respostas,
          totalGeral: conta.total_geral,
          franquiaMinima: conta.franquia_minima,
          agruparFaturamento: conta.agrupar_faturamento
        }).then((r) => {
          this.quantidadeDesconto = r.data.data.quantidadeDesconto;
          this.quantidade = r.data.data.quantidade;

          const plano = r.data.data.plano;
          if (plano) {
            this.plano = plano.plano_id;
            this.defaultPlano = {
              text: plano.nome_plano,
              value: plano.plano_id
            };
          }

          const consumo = r.data.data.consumo;
          this.enviado = consumo.enviado;
          this.recebido = consumo.recebido;
          this.total = consumo.total;

          this.faturado = this.conta.faturado == "S";
        });
      }
    }
  },

  methods: {
    abrir() {
      this.$refs["mdlGerarCobranca"].show();
    },

    validacao() {
      const frmGerarCobranca = this.$refs["frmGerarCobranca"];
      Inputmask().mask(frmGerarCobranca.querySelectorAll("input"));

      this.fv = formValidation(frmGerarCobranca, {
        fields: {
          dataVenda: {
            validators: {
              notEmpty: {
                message: "Campo obrigatório."
              },
              blank: {}
            }
          }
        },
        plugins: {
          trigger: new Trigger(),
          bootstrap: new Bootstrap()
        }
      });
    },

    gerarCobranca(e) {
      e.preventDefault();
      this.fv.validate().then((status) => {
        if (status == "Valid") {
          Swal.fire({
            title: "Deseja gerar a cobrança para esta conta?",
            showCancelButton: true,
            confirmButtonText: "Gerar Cobrança",
            cancelButtonText: "Cancelar",
            customClass: {
              cancelButton: "btn btn-secondary",
              confirmButton: "btn btn-success"
            },
            buttonsStyling: false
          }).then((result) => {
            if (result.value) {
              ApiService.post(`conta/integracao/gerar-cobranca`, {
                conta: this.conta.conta,
                plano: this.plano,
                desconto: this.desconto,
                quantidade: this.quantidade
              }).then(() => {
                this.conta.faturado = "S";

                this.$refs["mdlGerarCobranca"].hide();

                Swal.fire({
                  title: "MENSAGEM",
                  text: "Cobrança gerada com sucesso!",
                  icon: "success",
                  heightAuto: false
                });
              });
            }
          });
        }
      });
    },

    calcularDesconto(enviadas, recebidas, total) {
      return parseInt(recebidas) > parseInt(enviadas)
        ? Math.ceil(parseInt(enviadas) * 1.8)
        : parseInt(total);
    },

    limpar() {
      this.desconto = 0;
      this.plano = null;
      this.quantidade = 0;
      this.quantidadeDesconto = 0;
      this.defaultPlano.text = "Selecione";
      this.defaultPlano.value = null;
      this.faturado = false;
      this.enviado = 0;
      this.recebido = 0;
      this.total = 0;
      this.dataVenda = null;
      this.$emit("fechar:cobranca");
    },

    obterPlano(obj) {
      this.plano = obj ? obj.value : null;
    }
  }
};
</script>

<style>
.modal-open {
  overflow: hidden;
}
</style>
